<!-- 用户编辑弹窗 -->
<template>
  <el-dialog
    :title="isUpdate?'修改试题':'添加试题'"
    :visible="visible"
    width="60%"
    :destroy-on-close="true"
    :lock-scroll="false"
    custom-class="ele-dialog-form"
    @update:visible="updateVisible">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <!--      等级-->
      <el-form-item label="等级" prop="level_id">
        <el-select :span="18" v-model="ruleForm.level_id" placeholder="请选择等级">
          <el-option :span="18"
                     v-for="item in roleList"
                     :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <!--分数-->
      <el-form-item label="分数" prop="score">
        <el-col :span="5">
          <el-input v-model.number="ruleForm.score" placeholder="请输入分数"></el-input>
        </el-col>
      </el-form-item>
      <!--分数-->
      <el-form-item label="答题时长" prop="answer_time">
        <el-col :span="5">
          <el-input v-model="ruleForm.answer_time" oninput = "value=value.replace(/[^\d.]/g,'')" placeholder="请输入答题时长"></el-input>
          <span style="color: red">*单位：分钟</span>
        </el-col>
      </el-form-item>
      <!-- 编辑器 -->
      <el-form-item label="题干" prop="content">
        <el-col :span="18">
          <tinymce-editor class="tinymce-editor" v-model="ruleForm.content" :init="init"/>
        </el-col>
      </el-form-item>
      <el-form-item label="问题" :rules="{required: true, message: '问题不能为空', trigger: 'blur'}">
        <el-col :span="18">
          <el-input v-model="ruleForm.question" placeholder="请输入问题"></el-input>
        </el-col>
      </el-form-item>
      <!--选项-->
      <div class="form_add_problem">
        <el-form-item>
          <el-button type="danger" @click="removeOptions()">删除</el-button>
          <el-button type="primary" @click="addOptions()">新增</el-button>
        </el-form-item>
        <el-form-item v-for="(val,i) in ruleForm.options" :key="val.key"
                      label="选项" :prop=" '.options.' + i + '.chioptions'"
                      :rules="{required: true, message: '选项不能为空', trigger: 'blur'}"
        >
          <el-col :span="18">
            <el-input v-model="val.chioptions" placeholder=""></el-input>
          </el-col>
          <el-col :span="4" style="margin-left: 20px;">
            <el-form-item :prop="'.options.' + i + '.radio'" :rules="{
                      required: true, message: '请至少选择一个 ', trigger: 'blur'
                    }">

              <el-radio-group v-model="val.radio">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-form-item>


      </div>
    </el-form>
    <div slot="footer">
      <el-button
        @click="resetForm">取消
      </el-button>
      <el-button
        type="primary"
        @click="save"
        :loading="loading">保存
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import TinymceEditor from '@/components/TinymceEditor';

export default {
  name: 'UserEdit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object,
    // 父组件方法，关闭弹框
    openEdit: Function
  },
  components: {TinymceEditor},
  data() {
    return {
      // 表单数据
      init: {},
      // 表单验证规则
      ruleForm: Object.assign({
        level_id: '',
        score: '',
        answer_time: '',
        content:'',
        // 问题 - 选项
        question: '',
        options: [{chioptions: '', radio: 0}],

      }, this.data),
      // 等级下拉数据
      level_idSelection: [],
      rules: {
        score: [
          {required: true, message: '请输入分值', trigger: 'blur'}
        ],
        level_id: [
          {required: true, message: '请选择等级', trigger: 'blur'}
        ],
        answer_time: [
          {required: true, message: '请输入答题时长',trigger: 'blur'}
        ],
        stem: [
          {required: true, message: '请输入题干',trigger: 'blur'}
        ],

      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,
      // 角色列表
      roleList: [],

    }
  },
  //mounted
  watch: {
    //deep: true,
    data() {
      if (this.data) {
        this.$http['get']('/Admin/longSentenceInfo/' + this.data.id).then(res => {
          if (res.data.data.options) {
            this.data.question = res.data.data.question;
            this.data.options = res.data.data.options;
          } else {
            this.data =
              {
                problem: '',
                score: '',
                content:'',
                // 选项
                options: [{chioptions: '', radio: 0}],
              }
            ;
          }
          this.ruleForm = Object.assign({}, this.data);
          this.isUpdate = true;
        })
      } else {
        this.ruleForm = Object.assign({
          level_id: '',
          score: '',
          answer_time: '',
          content:'',
          // 问题 - 选项
          question: '',
          options: [{chioptions: '', radio: 0}],

        }, this.data)
        this.isUpdate = false;
      }

    }
  },
  mounted() {
    this.queryRoles();  // 查询角色列表
  },
  methods: {
    /* 保存编辑 */
    save() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
         this.loading = true;
          this.$http[this.isUpdate ? 'put' : 'post'](this.isUpdate ? '/Admin/longSentence/' + this.ruleForm.id : '/Admin/longSentence', this.ruleForm).then(res => {
            this.loading = false;
            if (res.data.code === 200) {
              this.$message({type: 'success', message: res.data.msg});
              if (!this.isUpdate) {
                this.ruleForm = {
                  level_id: '',
                  score: '',
                  answer_time: '',
                  content:'',
                  // 问题 - 选项
                  question: '',
                  options: [{chioptions: '', radio: 0}],
                };
              }
              this.updateVisible(false);
              this.$emit('done');
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            this.loading = false;
            this.$message.error(e.message);
          });
        } else {
          return false;
        }
      });
    },
    // 关闭
    resetForm(ruleForm) {
      // Object.assign(this.$data.ruleForm,this.$options.data().ruleForm)
      // this.$emit('update:visible', false);
      // 调用父组件方法 隐藏弹框
      this.openEdit(null)
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },
    /* 查询等级列表 */
    queryRoles() {
      this.$http.get('/Admin/level').then(res => {

        if (res.data.code === 200) {
          this.roleList = res.data.data;
          //console.log(this.roleList)
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(e => {
        this.$message.error(e.message);
      });
    },

    // 添加
    addOptions() {
      this.ruleForm.options.push({
        chioptions: '',
        radio: 0,
        key: Date.now()
      })
    },
    // 删除
    removeOptions() {
      this.ruleForm.options.splice(this.ruleForm.options.length - 1, 1)
    }
  }
}
</script>

<style scoped>
</style>
